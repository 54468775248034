import type { CustomerSaleDetailsPayload } from '@backmarket/http-api/src/api-specs-buyback/customer/getCustomerSaleDetails'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from '~/scopes/buyback/dashboard/components/SaleItem/SaleItem.translations'

export const confirmationDetailsAdapter = ({
  order,
  shipping,
  product,
}: CustomerSaleDetailsPayload) => {
  const i18n = useI18n()

  return {
    shippingMode: shipping?.mode,
    image: product.image,
    title: product.title,
    price: i18n.price(order.price),
    daysLeft: order.offerExpiryDays,
    details: [
      [
        ...(order.customerAssessment?.map(({ name, label, value }) => ({
          id: name,
          label,
          value,
        })) || []),
      ],
      [
        {
          id: translations.refurbisher.id,
          label: i18n(translations.refurbisher),
          value: order.merchantCompany,
        },
        {
          id: translations.shippingMode.id,
          label: i18n(translations.shippingMode),
          value: shipping?.modeLabel,
        },
        {
          id: translations.orderId.id,
          label: i18n(translations.orderId),
          value: order?.id,
        },
      ],
    ],
  }
}
