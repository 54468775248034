export default {
  imageAlt: {
    id: 'buyback_confirmation_image_alt',
    defaultMessage: 'Deal !',
  },
  title: {
    id: 'buyback_confirmation_main_title',
    defaultMessage: 'Wheeee! Almost done!',
  },
  subtitle: {
    id: 'buyback_confirmation_subtitle_paragraph',
    defaultMessage:
      'You’re on your way to giving your tech a renewed lease on life',
  },
  mySalesLink: {
    id: 'buyback_confirmation_my_sales_link',
    defaultMessage: 'Ship and get paid',
  },
  nextStepsTitle: {
    id: 'buyback_confirmation_next_steps_title',
    defaultMessage: 'What’s next',
  },
  infoBlockMessage: {
    id: 'buyback_confirmation_info_block_message',
    defaultMessage:
      'No room in your memory palace? You’ll get an email with all the info.',
  },
  saleInfo: {
    id: 'buyback_confirmation_sale_info',
    defaultMessage: 'Sale info',
  },
  appBannerTitle: {
    id: 'buyback_confirmation_app_banner_title',
    defaultMessage: 'BuyBack in your back pocket',
  },
  appBannerSubTitle: {
    id: 'buyback_confirmation_app_banner_subtitle',
    defaultMessage: 'Keep tabs on what you sell, no stress.',
  },
}
